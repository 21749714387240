<template>
  <div class="container">
    <div class="headers">
      <van-nav-bar
        :title="$t('jilu.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-tabs
        class="order-List"
        color="#004ea3"
        title-active-color="#004ea3"
        @change="change"
      >
        <div class="tab-list">
          <van-tab :title="$t('cxjilu')" name="tab2">
            <div class="list-wrapper">
              <van-list
                v-if="orders.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  class="currency-Increase"
                  v-for="item in orders"
                  :key="item.id"
                >
                  <van-col :span="12" style="text-align: left">
                    <!-- <span>{{ item.code }}</span>
                    <br /> -->
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('jilu.buy') }}:
                      <span>{{ item.total_price }}</span>
                    </span>
                    <br />
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('trade.jian') }}:
                      <span>{{ item.open_price }}</span>
                    </span>
                    <br />
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('jilu.biz') }}: {{ item.product_name }}
                    </span>
                  </van-col>
                  <van-col :span="12" class="currency-time">
                    <span>
                      <span v-if="item.status === '0'">
                        {{ $t('jiao-yi-zhong') }}
                      </span>
                      <span v-if="item.status === '1'">
                        {{ $t('yi-wan-cheng') }}
                      </span>
                      <br />
                      <span style="color: rgb(128, 128, 128)"
                        >{{ $t('jilu.price') }}：<span
                          v-if="item.profit < 0"
                          style="color: red"
                          >{{ item.profit }}</span
                        >
                        <span v-else style="color: green">{{
                          item.profit
                        }}</span>
                      </span>
                      <br />
                      <span style="color: rgb(128, 128, 128)">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
                  </van-col>
                </van-row>
              </van-list>
              <van-empty :description="$t('common.nomore')" v-else />
            </div>
          </van-tab>
          <van-tab :title="$t('chongzhi')" name="tab1-1">
            <div class="list-wrapper">
              <van-list
                v-if="recharges.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  class="currency-Increase"
                  v-for="item in recharges"
                  :key="item.id"
                  type="flex"
                  justify="space-between"
                >
                  <van-col :span="12" style="text-align: left">
                    <span>{{ item.code }}</span>
                    <br />
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('chong-bi-shu-liang') }} :
                      <span style="color: red">{{ item.recharge_num }}</span>
                    </span>
                  </van-col>
                  <van-col :span="12" class="currency-time">
                    <span>
                      <span v-if="item.status === '0'">
                        {{ $t('dai-shen-he') }}
                      </span>
                      <span v-if="item.status === '1'">
                        {{ $t('tong-guo') }}
                      </span>
                      <span v-if="item.status === '2'">
                        {{ $t('bo-hui') }}
                      </span>
                      <br />
                      <!-- <span style="color: rgb(128, 128, 128);">
                        {{ $t("chong-bi-lei-xing")  }}
                        ：{{ item.product_type }}
                        >
                      </span>
                      <br /> -->
                      <span style="color: rgb(128, 128, 128)">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
                  </van-col>
                  <van-col :span="24">
                    <span v-if="item.remark" style="color: reg(128, 128, 128)">
                      {{ $t('bei-zhu-itemproductname') }} : {{ item.remark }}
                    </span>
                  </van-col>
                </van-row>
              </van-list>
              <van-empty :description="$t('common.nomore')" v-else />
            </div>
          </van-tab>
          <van-tab :title="$t('tibi')" name="tab1-2">
            <div class="list-wrapper">
              <van-list
                v-if="withdraws.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  v-for="item in withdraws"
                  :key="item.id"
                  class="currency-Increase"
                  type="flex"
                  justify="space-between"
                >
                  <van-col span="12" style="text-align: left">
                    <span>{{ item.code }}</span>
                    <br />
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('tix.number') }} :
                      <span style="color: red; font-size: 16px">{{
                        item.withdraw_num
                      }}</span>
                    </span>
                    <br />
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('trade.fee') }} :
                      <span style="color: red; font-size: 16px"
                        >{{ item.service_charge }}%</span
                      >
                    </span>
                    <br />
                    <span style="color: reg(128, 128, 128)">
                      {{ $t('tix.number') }} :
                      <span style="color: red; font-size: 16px">{{
                        (item.withdraw_num * (100 - item.service_charge)) / 100
                      }}</span>
                    </span>
                  </van-col>
                  <van-col span="12" class="currency-time">
                    <span>
                      <span v-if="item.status === '0'" class="wait">
                        {{ $t('dai-shen-he') }}
                      </span>
                      <span v-if="item.status === '1'" class="pass">
                        {{ $t('tong-guo') }}
                      </span>
                      <span v-if="item.status === '2'" class="refused">
                        {{ $t('bo-hui') }}
                      </span>
                      <br />
                      <!-- <span style="color: rgb(128, 128, 128);">
                        {{ $t('ti-bi-lei-xing') }}
                        ：{{ item.product_type }}
                        >
                      </span>
                      <br /> -->
                      <span style="color: rgb(128, 128, 128)">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
                  </van-col>
                  <van-col span="24" style="text-align: left">
                    <span style="color: reg(128, 128, 128)">
                      <span class="pass2">{{
                        $t('bei-zhu-itemproductname')
                      }}</span>
                      :
                      <span style="color: red; font-size: 15px">{{
                        item.remark || '暂无'
                      }}</span>
                    </span>
                  </van-col>
                </van-row>
              </van-list>
              <van-empty :description="$t('common.nomore')" v-else />
            </div>
          </van-tab>
        </div>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      recharges: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false,
    }
  },
  created() {
    this.getRecharges()
    this.getTransfers()
  },
  mounted() {},
  methods: {
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/trade/get_orders')
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
    // 充值记录
    async getRecharges() {
      const { data } = await this.$http.get('/home/trade/get_recharges')
      if (data) {
        if (data.code === 200) {
          this.recharges = data.data
        }
      }
    },
    // 提币记录
    async getWithdraws() {
      const { data } = await this.$http.get('/home/trade/get_withdraws')
      if (data) {
        if (data.code === 200) {
          this.withdraws = data.data
        }
      }
    },
    // 提币记录
    async getTransfers() {
      const { data } = await this.$http.get('/home/trade/get_transfers')
      if (data) {
        if (data.code === 200) {
          this.transfers = data.data
        }
      }
    },
    change(name) {
      console.log(name)
      switch (name) {
        case 'tab2':
          this.getOrders()
          break
        case 'tab1-1':
          this.getRecharges()
          break
        case 'tab1-2':
          this.getWithdraws()
          break
      }
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.headers {
  height: 233px;
  box-shadow: none;
  background: url('../../assets/img/trad_bg.png');
  .van-nav-bar {
    background-color: transparent;
  }
  /deep/.van-icon {
    font-size: 16px;
    color: #171717 !important;
  }
  /deep/.van-nav-bar__title {
    font-size: 16px;
    color: #171717 !important;
  }
  .van-hairline--bottom::after {
    width: 0;
  }
}
.maincontent {
  position: relative;
  top: -10rem;
  padding: 20px 0 50px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: calc(100vh - 73px);
  overflow: hidden;
  // z-index: 100;

  .list-wrapper {
    height: calc(100vh - 137px);
    overflow-y: auto;
  }

  /deep/.van-tab {
    font-size: 1.04rem;
  }

  .currency-Increase {
    background-color: #fff;
    color: #000;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    border-bottom: 0.02667rem solid #e5e5e5;

    .currency-time {
      text-align: right;
      .wait {
        padding: 2px 10px;
        display: inline-block;
        font-size: 18px;
        color: #fff;
        background-color: #e08b17;
        border-radius: 5px;
      }
      .pass {
        padding: 2px 10px;
        display: inline-block;
        font-size: 18px;
        color: #fff;
        background-color: green;
        border-radius: 5px;
      }
      .refused {
        padding: 2px 10px;
        display: inline-block;
        font-size: 18px;
        color: #fff;
        background-color: red;
        border-radius: 5px;
      }
    }
  }
  .income {
    font-size: 1.2rem;
    color: #004ea3;
  }
  .tab-title {
    /deep/ .van-tabs__wrap {
      border-bottom: 1px solid #ccc;
    }
    // border-bottom: 1px solid #ccc;
  }
  .pass2 {
    padding: 0px 10px;
    display: inline-block;
    color: #fff;
    background-color: green;
    border-radius: 5px;
  }
}
</style>
